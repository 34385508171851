import React from "react"

const ComingSoonPage = () => {
  return (
    <div>
      coming-soon
    </div>
  )
}

export default ComingSoonPage
